"use strict";

// js code
// document.getElementById("js-btn").addEventListener("click", () => {
//   alert("JS compiles!!");
// });
// jquery code
$(document).ready(function () {
  $("#jquery-btn").click(function () {
    alert("jQuery Works.");
  });
});